<template>
  <div v-if="isInitialLoadCompleted" class="vue-app">
    <Context />
  </div>
</template>

<script>
const Context = () => import('../components/Context.vue')

export default {
  components: {
    Context
  },
  computed: {
    isInitialLoadCompleted() {
      return this.$store.getters['runtime-config/isInitialLoadCompleted']
    }
  }
}
</script>

<style lang="scss">
body {
  overflow: hidden;
}
.vue-app {
  height: 100vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.el-dialog__footer {
  position: relative;
  z-index: 1;

  .el-button {
    position: relative;

    &::after {
      width: calc(100% - 40px);
      height: 25px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 20px;
      box-shadow: 0 2px 20px 0 rgba($color--primary, 0.3), 0 2px 20px 0 rgba($background--shadow, 0.2);
      border-radius: 4px;
      z-index: -1;
    }
  }
}
</style>
