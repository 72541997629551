const ForgotPassword = () => import('../pages/ForgotPassword.vue')
const ResetPassword = () => import('../pages/ResetPassword.vue')

const Signup = () => import('../pages/Signup.vue')
const SignupConfirmation = () => import('../pages/SignupConfirmation.vue')

const routes = [
  {
    name: 'editor',
    path: '/editor/:projectId',
    component: () => import('../pages/Editor.vue'),
    meta: {
      shouldLoadProject: false
    }
  },
  {
    name: 'projects',
    path: '/projects',
    component: () => import('../pages/projects/index.vue'),
    meta: {
      showHeader: false
    }
  },
  {
    name: 'projectsNew',
    path: '/projects/new',
    component: () => import('../pages/projects/new.vue'),
    meta: {
      shouldLoadProject: true
    }
  },
  {
    name: 'projectsDetail',
    path: '/projects/:projectId',
    component: () => import('../pages/projects/detail.vue'),
    meta: {
      shouldLoadProject: true
    }
  },
  {
    name: 'default',
    path: '/',
    component: () => import('../pages/Home.vue'),
    meta: {
      showHeader: false
    }
  },
  {
    name: 'signup',
    path: '/signup',
    component: Signup,
    meta: {
      showHeader: false
    }
  },
  {
    name: 'signupConfirmation',
    path: '/signup/confirmation',
    component: SignupConfirmation,
    meta: {
      showHeader: false
    }
  },
  {
    name: 'signupConfirmationToken',
    path: '/signup/confirmation/:token',
    component: SignupConfirmation,
    meta: {
      showHeader: false
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../pages/Login.vue'),
    meta: {
      showHeader: false
    }
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      showHeader: false
    }
  },
  {
    name: 'resetPassword',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      showHeader: false
    }
  },
  {
    name: 'logout',
    path: '/logout',
    component: () => import('../pages/Logout.vue'),
    meta: {
      showHeader: false
    }
  },
  // Redirect all other incoming requests to `Login`
  {
    path: '*',
    redirect: { name: 'login' }
  }
]

export default routes
