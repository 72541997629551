var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isInitialLoadCompleted
    ? _c("div", { staticClass: "vue-app" }, [_c("Context")], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }