import runtimeConfig from '@jdi/vue-runtime-config'
import { Loading, Message, MessageBox, Notification } from 'element-ui'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createApp } from 'vue-demi'
import { createI18n } from 'vue-i18n-bridge'

import App from '../js/templates/App.vue'
import '../scss/main.scss'
import router from './router/router'
import store from './store/main.store'

Vue.use(runtimeConfig, { store, dispatchLoad: true })
Vue.use(VueI18n, { bridge: true })
Vue.use(Loading.directive)

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification

/**
 * For more information about the specific options
 * @url https://vuejs.org/v2/api/
 *
 * During development you want this to be true, so that you are aware that the build includes warnings
 * @bool false Vue.config.productionTip
 *
 * During development you want this to be true, so that you can use Developer Tools
 * @bool true Vue.config.devtools
 *
 * During development you want this to be false, so that Vue gives you hints about your code
 * @bool true Vue.config.silent
 *
 * Set this to true to enable component init, compile, render and patch performance tracing in the browser devtool performance/timeline panel.
 * Only works in development mode and in browsers that support the performance.mark API.
 * @bool false Vue.config.performance
 */

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.silent = true
  Vue.config.performance = false
} else {
  Vue.config.productionTip = false
  Vue.config.devtools = true
  Vue.config.silent = false
  Vue.config.performance = false
}

const i18n = createI18n(
  {
    legacy: false,
    lazy: true,
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    missingWarn: false,
    fallbackWarn: false,
    warnHtmlMessage: false
  },
  VueI18n
)

const app = createApp({
  components: {
    App
  },
  router,
  store,
  template: `<App />`
})

app.use(i18n)

app.mount('#vueApp')
